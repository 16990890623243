import { ICallInitialState } from '../types';
import { ICallPayloads } from './types';

/**
 * Will update call state
 *
 * @param {IEvaluationInitialState} state
 * @param {*} payload
 */
export const updateState = (state: ICallInitialState, payload: ICallPayloads) => ({
  ...state,
  loading: false,
  error: null,
  ...payload
});
