import React, { useReducer } from 'react';
import api from '../../api/api';
import conversationsReducer from './conversationsReducer';
import ConversationsContext from './conversationsContext';
import { useTranslation } from 'hooks/useTranslation';
import { useSnackbar } from 'notistack';
import { SnackBar } from 'components/SnackBar';

import {
  GET_CONVERSATIONS,
  CLEAR_STATE,
  CLEAR_MESSAGES,
  SET_ERROR,
  SET_LOADING,
  SEND_MESSAGE,
  CLEAR_ERROR,
  GET_CONVERSATION,
  MESSAGE_SOCKET,
  SET_FINAL,
  CONVERSATION_SOCKET,
  DELETE_CONVERSATION,
  UPDATE_CONVERSATION,
  GET_CONVERSATIONS_NO_READ
} from '../types';
import { channel } from 'service/pusher';

const DEFAULT_CONVERSATIONS_STATE = {
  conversation: {},
  conversations: [],
  pagination: {},
  lastItems: null,
  messages: [],
  loading: false,
  error: null,
  final: null,
  count: 0
};

const ConversationsState = (props) => {
  const { translate } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(conversationsReducer, DEFAULT_CONVERSATIONS_STATE);

  const updateConversation = async (values, conversationId, messageAlert = 'Conversation.UpdateConversation') => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.put(`/conversations/${conversationId}`, { ...values }, config);
      dispatch({ type: UPDATE_CONVERSATION, payload: res.data.data });
      enqueueSnackbar(translate(messageAlert), {
        content: (key, message) => <SnackBar id={key} message={message} type="success" />,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const deleteConversation = async (conversationId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.delete(`/conversations/${conversationId}`, config);
      dispatch({ type: DELETE_CONVERSATION, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const sendTemplate = async (values) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.post(`/conversations/template`, { ...values }, config);
      dispatch({ type: SEND_MESSAGE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
      enqueueSnackbar(translate(err.response.data.error), {
        content: (key, message) => <SnackBar id={key} message={message} type="error" />,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
      });
    }
  };

  const getConversations = async (values, reload) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(`/conversations`, { ...values }, config);
      dispatch({
        type: GET_CONVERSATIONS,
        payload: res.data?.data,
        pagination: res.data?.pagination,
        reload,
        final: res.data?.final
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getConversation = async (conversationId, lastConversationId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      await channel.unbind(`conversation_${lastConversationId}`);
      const res = await api.get(`/conversations/${conversationId}?isUpdate=true`, config);
      await channel.bind(`conversation_${conversationId}`, (data) => {
        addMessageSocket(data.message, data.conversation);
      });
      dispatch({ type: GET_CONVERSATION, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const addMessageSocket = (message, conversation) => dispatch({ type: MESSAGE_SOCKET, payload: message, conversation });

  const addConversationSocket = async (conversation) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.get(`/conversations/${conversation}?isUpdate=false`, config);
      dispatch({ type: CONVERSATION_SOCKET, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const sendMessage = async (values) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      if (values && values.files) {
        let files = [...values.files];
        delete values.files;

        for (let i = 0; i < files.length; i++) {
          let response = await api.post('/uploads/image', { type: files[i].type, fileName: files[i].name, file: files[i] }, config);
          await api.put(response.data.url, files[i], {
            headers: { 'Content-Type': files[i].type }
          });

          const res = await api.post(
            `/conversations/sendMessage`,
            {
              ...values,
              MediaUrl0: `${process.env.NEXT_PUBLIC_AWS_S3_URL}${response.data.key}`,
              MediaContentType0: files[i].type.replace('; codecs=opus', '')
            },
            config
          );
          dispatch({ type: SEND_MESSAGE, payload: res.data.data });
        }
      } else if (values && values.documents) {
        let documents = [...values.documents];
        delete values.documents;

        for (let i = 0; i < documents.length; i++) {
          const arrayFile = documents[i].file.split('.');
          const type = arrayFile[arrayFile.length - 1];

          const res = await api.post(
            `/conversations/sendMessage`,
            {
              ...values,
              MediaUrl0: `${process.env.NEXT_AWS_S3_URL}${documents[i].file}`,
              MediaContentType0: type
            },
            config
          );
          dispatch({ type: SEND_MESSAGE, payload: res.data.data });
        }
      } else {
        const res = await api.post(`/conversations/sendMessage`, { ...values }, config);
        dispatch({ type: SEND_MESSAGE, payload: res.data.data });
      }
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
      enqueueSnackbar(translate(err.response.data.error), {
        content: (key, message) => <SnackBar id={key} message={message} type="error" />,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
      });
    }
  };

  const getConversationsNoRead = async (query) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.post(`/conversations/advancedSearchDocuments`, query, config);
      let response = res?.data?.data;
      dispatch({ type: GET_CONVERSATIONS_NO_READ, payload: response?.data, count: response?.count });
    } catch (err) {
      setError(err);
    }
  };

  const setError = () => dispatch({ type: CLEAR_ERROR });
  const setLoading = () => dispatch({ type: SET_LOADING });
  const clearState = () => dispatch({ type: CLEAR_STATE });
  const clearMessages = () => dispatch({ type: CLEAR_MESSAGES });
  const setFinal = (value = false) => dispatch({ type: SET_FINAL, payload: value });

  return (
    <ConversationsContext.Provider
      value={{
        conversations: state.conversations,
        pagination: state.pagination,
        loading: state.loading,
        error: state.error,
        conversation: state.conversation,
        lastItems: state.lastItems,
        messages: state.messages,
        final: state.final,
        count: state.count,
        addConversationSocket,
        setError,
        setLoading,
        getConversations,
        deleteConversation,
        setFinal,
        updateConversation,
        getConversation,
        clearState,
        addMessageSocket,
        sendMessage,
        clearMessages,
        sendTemplate,
        getConversationsNoRead
      }}
    >
      {props.children}
    </ConversationsContext.Provider>
  );
};

export default ConversationsState;
