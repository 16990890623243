import { GET_TEMPLATES, GET_TEMPLATE, UPDATE_TEMPLATE, CLEAR_STATE, SET_LOADING, SET_ERROR, CREATE_TEMPLATE } from '../types';
import { DEFAULT_TEMPLATE_STATE } from './constants';

export default (state, action) => {
  switch (action.type) {
    case GET_TEMPLATES:
      return {
        ...state,
        count: action?.count || 0,
        templates: action.payload,
        loading: false,
        error: null
      };
    case GET_TEMPLATE:
      return {
        ...state,
        template: action.payload,
        loading: false,
        error: null
      };
    case UPDATE_TEMPLATE:
      return {
        ...state,
        template: action.payload,
        loading: false,
        error: null
      };
    case CLEAR_STATE:
      return {
        ...state,
        ...DEFAULT_TEMPLATE_STATE
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case CREATE_TEMPLATE:
      return {
        ...state,
        template: action.payload,
        loading: false,
        error: null
      };
    default:
      return { ...state };
  }
};
