import { Dispatch } from 'react';
import { IMakeCall } from './types';
import { MAKE_CALL } from '../constants';
import { TCallInfo } from '../types';

/**
 * Will set the call information in the state
 *
 * @param {Dispatch<IMakeCall>} dispatch Function that update state
 */
export const makeCall = (dispatch: Dispatch<IMakeCall>, props: TCallInfo) => dispatch({ type: MAKE_CALL, payload: props });
