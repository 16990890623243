import React, { useReducer, createContext } from 'react';
import { DEFAULT_CALL_STATE } from './constants';
import callReducer from './callReducer';
import { clearSnackbars, clearState } from 'contexts/utils';
import { setLoading } from 'contexts/utils/setLoading';
import { makeCall, hangUpCall, generateToken } from './utils';
import { TCallInfo } from './types';

export const CallContext = createContext(DEFAULT_CALL_STATE);

export const CallState = (props) => {
  const [state, dispatch] = useReducer(callReducer, DEFAULT_CALL_STATE);

  return (
    <CallContext.Provider
      value={{
        ...state,
        clearState: () => clearState(dispatch, DEFAULT_CALL_STATE),
        setLoading: () => setLoading(dispatch),
        clearSnackbars: () => clearSnackbars(dispatch),
        makeCall: (props: TCallInfo) => makeCall(dispatch, props),
        hangUpCall: () => hangUpCall(dispatch),
        generateToken: async (teamId: string) => await generateToken(teamId, dispatch)
      }}
    >
      {props.children}
    </CallContext.Provider>
  );
};
