import { CLEAR_SNACKBAR } from 'contexts/types';
import { Dispatch } from 'react';
import { IClearSnackbars } from './types';

/**
 * Will clear the snackbars messages
 *
 * @param {Dispatch<IClearSnackbars>} dispatch Function that update state
 * @param {number} [time] Time to wait before clearing the snackbars
 */
export const clearSnackbars = (dispatch: Dispatch<IClearSnackbars>, time?: number) => {
  setTimeout(() => dispatch({ type: CLEAR_SNACKBAR }), time || 1000);
};
