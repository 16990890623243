import React, { useReducer, createContext } from 'react';
import { DEFAULT_TEMPLATE_STATE } from './constants';
import { SET_LOADING, SET_ERROR, CLEAR_STATE, GET_TEMPLATES, GET_TEMPLATE, UPDATE_TEMPLATE, CREATE_TEMPLATE } from '../types';
import templateReducer from './templateReducer';
import api from '../../api/api';

export const TemplateContext = createContext();

const TemplateState = (props) => {
  const [state, dispatch] = useReducer(templateReducer, DEFAULT_TEMPLATE_STATE);

  const clearState = () => dispatch({ type: CLEAR_STATE });
  const setLoading = () => dispatch({ type: SET_LOADING });

  const getTemplates = async (query) => {
    clearState();
    setLoading();
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      };
      const res = await api.post('/templates/advancedSearchDocuments', query, config);
      dispatch({ type: GET_TEMPLATES, payload: res.data.data, count: res.data.pagination.total ?? 0 });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err?.response?.data });
    }
  };
  const getTemplate = async (id) => {
    clearState();
    setLoading();
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      };
      const res = await api.get(`/templates/${id}`, config);
      dispatch({ type: GET_TEMPLATE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err?.response?.data });
    }
  };

  const createTemplate = async (data) => {
    clearState();
    setLoading();
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      };
      const res = await api.post('/templates', data, config);
      dispatch({ type: CREATE_TEMPLATE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err?.response?.data });
    }
  };

  const updateTemplate = async (id, data) => {
    clearState();
    setLoading();
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      };
      const res = await api.put(`/templates/${id}`, data, config);
      dispatch({ type: UPDATE_TEMPLATE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err?.response?.data });
    }
  };

  return (
    <TemplateContext.Provider
      value={{
        ...state,
        setLoading,
        clearState,
        getTemplates,
        getTemplate,
        createTemplate,
        updateTemplate
      }}
    >
      {props.children}
    </TemplateContext.Provider>
  );
};

export default TemplateState;
