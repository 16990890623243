import { Dispatch } from 'react';
import { IHangUpCall } from './types';
import { HANG_UP_CALL } from '../constants';

/**
 * Will hang up the call
 *
 * @param {Dispatch<IHangUpCall>} dispatch Function that update state
 */
export const hangUpCall = (dispatch: Dispatch<IHangUpCall>) => dispatch({ type: HANG_UP_CALL, payload: false });
