import {
  GET_CONVERSATIONS,
  CLEAR_STATE,
  SET_ERROR,
  SET_LOADING,
  CLEAR_ERROR,
  GET_CONVERSATION,
  MESSAGE_SOCKET,
  SET_FINAL,
  CONVERSATION_SOCKET,
  DELETE_CONVERSATION,
  CREATE_LEAD,
  UPDATE_CONVERSATION,
  GET_CONVERSATIONS_NO_READ
} from '../types';
import _ from 'lodash';
import { filterUniqueID } from 'utils/uniqueIdArray';

export default (state, action) => {
  switch (action.type) {
    case UPDATE_CONVERSATION:
      let indexE = state.conversations.findIndex((item) => item._id === action.payload._id);
      state.conversations[indexE] = action.payload;
      return {
        ...state,
        conversation: action.payload,
        loading: false
      };
    case CREATE_LEAD:
      let indexU = state.conversations.findIndex((item) => item._id === action.payload._id);
      state.conversations[indexU] = action.payload;
      return {
        ...state,
        conversation: action.payload,
        loading: false
      };
    case DELETE_CONVERSATION:
      let deleteC = state.conversations.filter((item) => item._id !== action.payload);
      return {
        ...state,
        loading: false,
        conversation: {},
        conversations: deleteC
      };
    case SET_FINAL:
      return {
        ...state,
        final: action.payload
      };
    case CONVERSATION_SOCKET:
      let filterConversations = state.conversations.filter((item) => item._id.toString() !== action.payload._id.toString());
      const conversations = [action.payload, ...filterConversations];
      return {
        ...state,
        conversations: filterUniqueID(conversations)
      };
    case MESSAGE_SOCKET:
      let converMessage = _.findIndex(state.conversations, (item) => item._id === action.conversation);

      if (converMessage !== -1) {
        const currentConverMessage = state.conversations[converMessage].messages;
        const updatedConverMessage = Array.isArray(currentConverMessage) ? currentConverMessage : [];
        state.conversations[converMessage].messages = [...updatedConverMessage, action.payload];
      }

      const currentConversationMessages = state.conversation.messages;
      const updatedConversationMessages = Array.isArray(currentConversationMessages) ? currentConversationMessages : [];
      const currentMessages = state.messages;
      const updatedMessages = Array.isArray(currentMessages) ? currentMessages : [];

      return {
        ...state,
        conversation: {
          ...state.conversation,
          messages: [...updatedConversationMessages, action.payload]
        },
        messages: [...updatedMessages, action.payload]
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null
      };
    case GET_CONVERSATION:
      let isRead = _.findIndex(state.conversations, (item) => item._id === action.payload._id);
      state.conversations[isRead] = action.payload;

      return {
        ...state,
        messages: action.payload.messages,
        conversation: action.payload,
        loading: false
      };

    case GET_CONVERSATIONS:
      let convers = [];
      if (action.reload) convers = action.payload;
      else convers = [...state.conversations, ...action.payload];

      return {
        ...state,
        conversations: filterUniqueID(convers),
        pagination: action.pagination,
        lastItems: action.payload.length,
        final: action.final,
        loading: false
      };

    case GET_CONVERSATIONS_NO_READ:
      return {
        ...state,
        count: action?.count || 0,
        loading: false
      };

    case CLEAR_STATE:
      return {
        ...state,
        conversation: {},
        conversations: [],
        pagination: {},
        messages: [],
        error: null
      };

    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loadig: false
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };

    default:
      return state;
  }
};
