import { SET_ERROR } from '../types';
import { AxiosError } from 'axios';
import { ISetError } from './types';

/**
 * Wull return if axios has an error from backend
 *
 * @param {*} error
 * @return {*}  {error is { response: { data: any } }}
 */
const isAxiosError = (error: AxiosError) => {
  return error?.response?.data !== undefined;
};

/**
 * Will handle state's errors in order to be from axios or an
 * unknown error
 *
 * @param {*} error
 * @return {*}  {ISetError}
 */
export const handleAxiosError = (error: AxiosError): ISetError => {
  if (isAxiosError(error)) {
    return { type: SET_ERROR, payload: error?.response?.data as string };
  } else {
    return { type: SET_ERROR, payload: 'unknown error' };
  }
};
