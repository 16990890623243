import { CLEAR_SNACKBAR, CLEAR_STATE, SET_LOADING } from '../types';
import { GENERATE_TOKEN, HANG_UP_CALL, MAKE_CALL } from './constants';
import { ICallInitialState, TCallActionTypes } from './types';
import { updateState } from './utils/updateState';

export default (state: ICallInitialState, action: TCallActionTypes): ICallInitialState => {
  switch (action.type) {
    case MAKE_CALL:
      const { name, phone, callerId } = action.payload;
      return updateState(state, {
        isCalling: true,
        callInfo: {
          name,
          phone,
          callerId
        }
      });
    case HANG_UP_CALL:
      return updateState(state, {
        isCalling: false,
        callInfo: null
      });
    case GENERATE_TOKEN:
      return updateState(state, {
        token: action.payload
      });
    case CLEAR_SNACKBAR:
      return updateState(state, {
        success: null,
        error: null
      });
    case CLEAR_STATE:
      return action.payload;
    case SET_LOADING:
      return updateState(state, { loading: true });
    default:
      return state;
  }
};
