import { Dispatch } from 'react';
import { CLEAR_STATE } from 'contexts/types';
import { IClearState, TInitialState } from './types';

/**
 * Will clear the state to default values
 *
 * @param {Dispatch<IClearState>} dispatch Function that update state
 * @param {TInitialState} initialState Initial state
 */
export const clearState = async (dispatch: Dispatch<IClearState>, initialState: TInitialState) =>
  dispatch({ type: CLEAR_STATE, payload: initialState });
