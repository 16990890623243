import React, { useReducer } from 'react';
import api from '../../api/api';
import adAccountContext from './adAccountContext';
import adAccountReducer from './adAccountReducer';
import {
  GET_AD_ACCOUNT,
  GET_AD_ACCOUNTS,
  SAVE_AD_ACCOUNT,
  UPDATE_AD_ACCOUNT,
  CLEAR_STATE,
  SET_ERROR,
  SET_LOADING,
  SUBSCRIBE_WEBHOOK,
  REMOVE_ACCOUNT_FROM_TEAM,
  GET_TEAM_AD_ACCOUNTS
} from '../types';

import { DOCUMENT_DELETED_ALERT, DOCUMENT_UPDATED_ALERT } from 'components/CustomAlert/constants/alerts';
import CustomAlert from 'components/CustomAlert';
import { SUCCESS_STATUS_ICON } from 'components/CustomAlert/constants/status';
import { getErrorMessage } from 'contexts/utils/getErrorMessage';
import { useTranslation } from 'hooks/useTranslation';

export const ADACCOUNTS_TYPE = 'adAccounts';
export const TEAM_ADACCOUNTS_TYPE = 'teamAdAccounts';

export const DEFAULT_ADACCOUNT_STATE = {
  [ADACCOUNTS_TYPE]: [],
  adAccount: {},
  loading: false,
  error: null,
  count: 0,
  success: false,
  [TEAM_ADACCOUNTS_TYPE]: [],
  teamAdAccountsCount: 0
};

const AdAccountState = (props) => {
  const { translate } = useTranslation();
  const [state, dispatch] = useReducer(adAccountReducer, DEFAULT_ADACCOUNT_STATE);

  //Create Ad Account
  const createAdAccount = async ({ ...values }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    setLoading();
    try {
      const res = await api.post(`/accounts`, { ...values }, config);
      dispatch({ type: SAVE_AD_ACCOUNT, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getAdAccountByUser = async (userId) => {
    clearState();
    setLoading();
    try {
      const res = await api.get(`/accounts/${userId}`);
      dispatch({ type: GET_AD_ACCOUNT, payload: res.data.data });
    } catch (err) {
      setError(err);
    }
  };

  const getAdAccountsByUser = async (userId, source) => {
    clearState();
    setLoading();
    try {
      const res = await api.get(`/accounts/all/${userId}?source=${source}`);
      dispatch({ type: GET_AD_ACCOUNTS, payload: res.data.data });
    } catch (err) {
      setError(err);
    }
  };

  const getAdAccount = async (accountId) => {
    setLoading();
    try {
      const res = await api.get(`/accounts/only/${accountId}`);
      dispatch({ type: GET_AD_ACCOUNT, payload: res.data.data });
    } catch (err) {
      setError(err);
    }
  };

  const updateAdAccount = async ({ id, values, handleThen, isAlertDisabled }) => {
    setLoading();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.put(`/accounts/${id}`, { ...values }, config);
      if (!isAlertDisabled) {
        CustomAlert({
          title: translate(DOCUMENT_UPDATED_ALERT),
          icon: SUCCESS_STATUS_ICON,
          handleThen
        });
      }
      dispatch({ type: UPDATE_AD_ACCOUNT, payload: res.data.data });
    } catch (err) {
      setError(err);
    }
  };

  const removeAdAccountFromTeam = async (id, handleThen) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.put(`/accounts/${id}`, { team: null }, config);
      dispatch({ type: REMOVE_ACCOUNT_FROM_TEAM, payload: res.data.data, teamAdAccountsCount: (state.teamAdAccountsCount -= 1) });
      CustomAlert({
        title: translate(DOCUMENT_DELETED_ALERT),
        icon: SUCCESS_STATUS_ICON,
        handleThen
      });
    } catch (err) {
      setError(err);
    }
  };

  const getAdAccountsByTeam = async (teamId, source) => {
    clearState();
    setLoading();
    try {
      const res = await api.get(`/accounts/team/all/${teamId}?source=${source}`);
      dispatch({ type: GET_AD_ACCOUNTS, payload: res.data.data });
    } catch (err) {
      setError(err);
    }
  };

  const subscribeWebhookFb = async (values) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(`/accounts/subscribe/facebookWebhook`, { ...values }, config);
      dispatch({ type: SUBSCRIBE_WEBHOOK, payload: res.data.data });
    } catch (err) {
      setError(err);
    }
  };

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  const setAdAccount = async (adAccount) => dispatch({ type: GET_AD_ACCOUNT, payload: adAccount });

  const getAdvancedSearchAdAccount = async (query) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearState();
    setLoading();
    try {
      const res = await api.post(`/accounts/advancedSearchDocument`, query, config);
      dispatch({ type: GET_AD_ACCOUNT, payload: res.data.data });
    } catch (err) {
      setError(err);
    }
  };

  const getAdvancedSearchAdAccounts = async (query, accountstype = ADACCOUNTS_TYPE) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.post(`/accounts/advancedSearchDocuments`, query, config);
      let response = res?.data?.data;
      if (accountstype === ADACCOUNTS_TYPE) dispatch({ type: GET_AD_ACCOUNTS, payload: response?.data, count: response?.count });
      else dispatch({ type: GET_TEAM_AD_ACCOUNTS, payload: response?.data, count: response?.count });
    } catch (err) {
      setError(err);
    }
  };

  const getWspQrAdAccount = async (values) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    if (token) config.headers.Authorization = `Bearer ${token}`;
    try {
      const res = await api.post(`/accounts/whatsapp/qr`, { ...values }, config);
      dispatch({ type: SAVE_AD_ACCOUNT, payload: res.data.data });
    } catch (error) {
      setError(error);
    }
  };

  const setError = async (err) => dispatch({ type: SET_ERROR, payload: translate(getErrorMessage(err.response.data)) });

  return (
    <adAccountContext.Provider
      value={{
        ...state,
        createAdAccount,
        getAdAccountByUser,
        updateAdAccount,
        clearState,
        setLoading,
        getAdAccountsByUser,
        getAdAccount,
        getAdAccountsByTeam,
        setAdAccount,
        subscribeWebhookFb,
        getAdvancedSearchAdAccount,
        getAdvancedSearchAdAccounts,
        removeAdAccountFromTeam,
        getWspQrAdAccount
      }}
    >
      {props.children}
    </adAccountContext.Provider>
  );
};

export default AdAccountState;
