import React, { ReactElement, ReactNode, useEffect } from 'react';
import Script from 'next/script';
import '../styles/globals.css';
import '../scss/style.scss';
import 'components/Spinner/Spinner.css';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import Locales from 'ui-component/Locales';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import Notistack from 'ui-component/third-party/Notistack';
import ThemeCustomization from 'themes';
import { store } from 'store';
import { ConfigProvider } from 'contexts/ConfigContext';
import NavigationScroll from 'layout/NavigationScroll';
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import AuthState from 'contexts/auth/AuthState';
import AlertState from 'contexts/alert/AlertState';
import TokenState from 'contexts/token/TokenState';
import AdAccountState from 'contexts/adAccount/adAccountState';
import ChartState from 'contexts/charts/ChartState';
import TeamState from 'contexts/team/TeamState';
import UserState from 'contexts/user/UserState';
import HookSate from 'contexts/hook/HookState';
import FaceApiGraphState from 'contexts/faceApiGraph/FaceApiGraphState';
import ConversationsState from 'contexts/conversations/conversationsState';
import LeadState from 'contexts/lead/LeadState';
import TaskState from 'contexts/task/TaskState';
import AppointmentState from 'contexts/appointment/AppointmentState';
import EventState from 'contexts/event/EventState';
import SaleState from 'contexts/sale/SaleState';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import ReportState from 'contexts/report/ReportState';
import { PocketState } from 'contexts';
import { CallState } from 'contexts/call';
import { CSPostHogProvider } from 'service/posthog';
import TemplateState from 'contexts/templates/TemplateState';

type LayoutProps = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

interface Props {
  Component: LayoutProps;
}

declare global {
  interface Window {
    fbAsyncInit?: () => void;
    FB?: any;
  }
}

function MyApp({ Component, pageProps }: AppProps & Props) {
  const getLayout = Component.getLayout ?? ((page: any) => page);

  useEffect(() => {
    if (typeof window.FB === 'undefined') {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: '850862176156291',
          cookie: true,
          xfbml: true,
          version: 'v15.0'
        });
      };
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode?.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    }
  }, []);
  return (
    <React.Fragment>
      <Script
        strategy="lazyOnload"
        src="https://connect.facebook.net/en_US/sdk.js"
        onLoad={() => {
          if (window.FB) {
            window.FB.init({
              appId: `850862176156291`,
              cookie: true,
              xfbml: true,
              version: 'v15.0'
            });
          }
        }}
      />
      <script src="https://accounts.google.com/gsi/client" async defer></script>

      <script src="https://accounts.google.com/gsi/client" async defer></script>

      <Provider store={store}>
        <CSPostHogProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
            <Notistack>
              <AuthProvider>
                <ConfigProvider>
                  <ThemeCustomization>
                    <RTLLayout>
                      <Locales>
                        <NavigationScroll>
                          <AuthState>
                            <CallState>
                              <AlertState>
                                <TokenState>
                                  <AdAccountState>
                                    <ChartState>
                                      <TeamState>
                                        <UserState>
                                          <HookSate>
                                            <FaceApiGraphState>
                                              <ConversationsState>
                                                <TaskState>
                                                  <SaleState>
                                                    <AppointmentState>
                                                      <EventState>
                                                        <LeadState>
                                                          <ReportState>
                                                            <TemplateState>
                                                              <PocketState>
                                                                {getLayout(<Component {...pageProps} />)}
                                                                <Snackbar />
                                                              </PocketState>
                                                            </TemplateState>
                                                          </ReportState>
                                                        </LeadState>
                                                      </EventState>
                                                    </AppointmentState>
                                                  </SaleState>
                                                </TaskState>
                                              </ConversationsState>
                                            </FaceApiGraphState>
                                          </HookSate>
                                        </UserState>
                                      </TeamState>
                                    </ChartState>
                                  </AdAccountState>
                                </TokenState>
                              </AlertState>
                            </CallState>
                          </AuthState>
                        </NavigationScroll>
                      </Locales>
                    </RTLLayout>
                  </ThemeCustomization>
                </ConfigProvider>
              </AuthProvider>
            </Notistack>
          </LocalizationProvider>
        </CSPostHogProvider>
      </Provider>
    </React.Fragment>
  );
}

export default MyApp;
