import React, { useReducer } from 'react';
import AuthContext from './authContext';
import AuthReducer from './authReducer';
import api from '../../api/api';
import {
  USER_LOADED,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGIN_FAIL,
  CLEAR_STATE,
  UPDATE_PROFILE,
  UPDATE_PASSWORD,
  SET_ERROR,
  SET_LOADING,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  GET_STATIC_LOOKUP_DATA,
  SET_AUTH_USER
} from '../types';

import { DEFAULT_AUTH_STATE } from './constants';

const AuthState = (props) => {
  const [state, dispatch] = useReducer(AuthReducer, DEFAULT_AUTH_STATE);

  const clearState = () => dispatch({ type: CLEAR_STATE });

  //Set Current User
  const loadUser = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    try {
      const res = await api.get('/auth/me', config);

      dispatch({
        type: USER_LOADED,
        payload: res.data.data
      });
    } catch (err) {
      dispatch({ type: LOGIN_FAIL, payload: err.response.data.error });
    }
  };

  //Login User
  const login = async (values) => {
    clearState();
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    setLoading();
    try {
      const res = await api.post('/auth/login', values, config);
      dispatch({ type: LOGIN_SUCCESS, payload: res.data });
      localStorage.setItem('token', res.data.token);
      loadUser();
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response.data.error
      });
    }
  };
  const register = async (values) => {
    clearState();

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      const res = await api.post('/auth/register', values, config);
      dispatch({ type: REGISTER_SUCCESS, payload: res.data });
      localStorage.setItem('token', res.data.token);
      loadUser();
    } catch (err) {
      dispatch({
        type: REGISTER_FAIL,
        payload: err.response.data.error
      });
    }
  };

  //Update profile
  const updateProfile = async (values, type) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    setLoading();
    let res;
    try {
      if (type === 'photo') {
        const uploadConfig = await api.post('/uploads/image', { type: values.type, fileName: values.name }, config);

        await api.put(uploadConfig.data.url, values, {
          headers: { 'Content-Type': values ? values.type : null }
        });

        const dataKey = uploadConfig.data.key;

        res = await api.put(`/auth/updatedetails`, { image: `${process.env.NEXT_AWS_S3_URL}${dataKey}` }, config);
      } else {
        res = await api.put('/auth/updatedetails', values, config);
      }

      dispatch({
        type: UPDATE_PROFILE,
        payload: res.data.data
      });
    } catch (err) {
      dispatch({
        type: SET_ERROR,
        payload: err.response.data
      });
    }
  };

  //updatePassword
  const updatePassword = async (values) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();

    try {
      const res = await api.put('/auth/updatepassword', { ...values }, config);
      dispatch({ type: UPDATE_PASSWORD, payload: res.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  // Logout
  const logout = () => dispatch({ type: LOGOUT });

  const facebookLogin = async (response) => {
    setLoading();
    try {
      let image = `https://graph.facebook.com/${response.id}/picture?access_token=${response.accessToken}&type=large`;

      response.image = image;

      const res = await api.post('/auth/facebook-login', response);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
        facebook: response
      });
      localStorage.setItem('token', res.data.token);
      loadUser();
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response.data
      });
    }
  };
  const googleLogin = async (response) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    };
    setLoading();
    try {
      const res = await api.post('/auth/google-login', response, config);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      });
      localStorage.setItem('token', res.data.token);
      loadUser();
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response.data
      });
    }
  };

  const getStaticLookupData = async (teamId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      if (state?.staticLookupData) return;
      const res = await api.post(`/auth/staticLookupData`, { teamId }, config);
      dispatch({ type: GET_STATIC_LOOKUP_DATA, payload: res.data.data });
    } catch (err) {
      console.log(err);
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const setLoading = () => dispatch({ type: SET_LOADING });

  const setUser = async (user) => dispatch({ type: SET_AUTH_USER, payload: user });

  return (
    <AuthContext.Provider
      value={{
        ...state,
        loadUser,
        clearState,
        facebookLogin,
        googleLogin,
        logout,
        login,
        updateProfile,
        updatePassword,
        register,
        getStaticLookupData,
        setUser
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
