import { ICallInitialState } from '../types';

export const DEFAULT_CALL_STATE: ICallInitialState = {
  isCalling: false,
  isInCall: false,
  loading: false,
  success: null,
  error: null,
  callInfo: null,
  token: null,
  clearState: () => {},
  setLoading: () => {},
  clearSnackbars: () => {},
  makeCall: () => {},
  hangUpCall: () => {},
  generateToken: async () => ''
};
