import React, { useReducer } from 'react';
import TeamContext from './teamContext';
import teamReducer from './teamReducer';
import api from '../../api/api';
import {
  SET_LOADING,
  CREATE_TEAM,
  SET_ERROR,
  CLEAR_STATE,
  GET_TEAM,
  UPDATE_TEAM,
  GET_TEAMS,
  SET_TEAM,
  ADD_QUICK_RESPONSE_TO_TEAM,
  UPDATE_QUICK_RESPONSE_IN_TEAM,
  REMOVE_QUICK_RESPONSE_FROM_TEAM,
  ADD_TAG_TO_TEAM,
  UPDATE_TAG_IN_TEAM,
  REMOVE_TAG_FROM_TEAM,
  CREATE_SOURCE,
  UPDATE_SOURCE,
  DELETE_SOURCE,
  CLEAR_SNACKBAR
} from '../types';
import CustomAlert from 'components/CustomAlert';

import { useIntl } from 'react-intl';
import { DOCUMENT_CREATED_ALERT, DOCUMENT_UPDATED_ALERT } from 'components/CustomAlert/constants/alerts';
import { SUCCESS_STATUS_ICON } from 'components/CustomAlert/constants/status';
import useAuth from 'hooks/useAuth';

export const DEFAULT_TEAM_STATE = {
  teams: [],
  team: {},
  loading: false,
  error: null,
  count: 0,
  success: false,
  sources: []
};

const TeamState = (props) => {
  const intl = useIntl();
  const { setUser, user } = useAuth();
  const [state, dispatch] = useReducer(teamReducer, DEFAULT_TEAM_STATE);

  const setSnackbarInterval = (time = 1000) => {
    setTimeout(() => dispatch({ type: CLEAR_SNACKBAR }), time);
  };

  const createSource = async (teamId, source) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(`/team/${teamId}/source`, source, config);
      setTeam(res.data.data);
      dispatch({ type: CREATE_SOURCE, payload: res.data.data, successMessage: res.data.message });
      setSnackbarInterval();
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.message });
      setSnackbarInterval();
    }
  };

  const updateSource = async (teamId, sourceId, source) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.put(`/team/${teamId}/source/${sourceId}`, source, config);
      setTeam(res.data.data);
      dispatch({ type: UPDATE_SOURCE, payload: res.data.data, successMessage: res.data.message });
      setSnackbarInterval();
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.message });
      setSnackbarInterval();
    }
  };

  const deleteSource = async (teamId, sourceId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.delete(`/team/${teamId}/source/${sourceId}`, config);
      setTeam(res.data.data);
      dispatch({ type: DELETE_SOURCE, payload: res.data.data, successMessage: res.data.message });
      setSnackbarInterval();
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.message });
      setSnackbarInterval();
    }
  };

  //Create team
  const createTeam = async (newTeam, handleThen, setState = true) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(`/team`, newTeam, config);
      let createdTeam = res.data.data;
      let userTeam = { _id: createdTeam?._id, owner: createdTeam?.owner?._id || createdTeam?.owner };
      setUser({ ...user, teams: [...(user?.teams || []), userTeam] });
      dispatch({ type: CREATE_TEAM, payload: createdTeam, setState });
      CustomAlert({
        title: intl.formatMessage({ id: DOCUMENT_CREATED_ALERT }),
        icon: SUCCESS_STATUS_ICON,
        handleThen
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };
  const getTeam = async (userId) => {
    clearState();
    setLoading();
    try {
      const res = await api.get(`/team/user/${userId}`);
      dispatch({ type: GET_TEAM, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getOnlyTeam = async (id) => {
    setLoading();
    try {
      const res = await api.get(`/team/${id}`);
      dispatch({ type: GET_TEAM, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const updateTeam = async (id, values, handleThen) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.put(`/team/${id}`, { ...values }, config);
      dispatch({ type: UPDATE_TEAM, payload: res.data.data });
      CustomAlert({
        title: intl.formatMessage({ id: DOCUMENT_UPDATED_ALERT }),
        icon: SUCCESS_STATUS_ICON,
        handleThen
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const addQuickResponseToTeam = async ({ teamId, quickResponses }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(`/team/${teamId}/addQuickResponse`, { quickResponses }, config);
      dispatch({ type: ADD_QUICK_RESPONSE_TO_TEAM, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const updateQuickResponseInTeam = async ({ teamId, quickResponses }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.put(`/team/${teamId}/updateQuickResponse`, { ...quickResponses }, config);
      dispatch({ type: UPDATE_QUICK_RESPONSE_IN_TEAM, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const removeQuickResponseFromTeam = async ({ teamId, quickResponseId }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(`/team/${teamId}/removeQuickResponse`, { quickResponseId }, config);
      dispatch({ type: REMOVE_QUICK_RESPONSE_FROM_TEAM, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const addTagToTeam = async ({ teamId, tag }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(`/team/${teamId}/addTag`, { tag }, config);
      dispatch({ type: ADD_TAG_TO_TEAM, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const updateTagInTeam = async ({ teamId, tags }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.put(`/team/${teamId}/updateTag`, { ...tags }, config);
      dispatch({ type: UPDATE_TAG_IN_TEAM, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const removeTagFromTeam = async ({ teamId, tagId }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(`/team/${teamId}/removeTag`, { tagId }, config);
      dispatch({ type: REMOVE_TAG_FROM_TEAM, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  const getAdvancedSearchTeam = async (query) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearState();
    setLoading();
    try {
      const res = await api.post(`/team/advancedSearchDocument`, query, config);
      dispatch({ type: GET_TEAM, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getAdvancedSearchTeams = async (query, options) => {
    let { needClearState = true } = options;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    if (needClearState) clearState();
    setLoading();
    try {
      const res = await api.post(`/team/advancedSearchDocuments`, query, config);
      dispatch({ type: GET_TEAMS, payload: res?.data?.data?.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const setTeam = async (team) => dispatch({ type: SET_TEAM, payload: team });

  return (
    <TeamContext.Provider
      value={{
        ...state,
        setLoading,
        clearState,
        createTeam,
        updateTeam,
        getTeam,
        getOnlyTeam,
        getAdvancedSearchTeam,
        getAdvancedSearchTeams,
        setTeam,
        addQuickResponseToTeam,
        updateQuickResponseInTeam,
        removeQuickResponseFromTeam,
        addTagToTeam,
        updateTagInTeam,
        removeTagFromTeam,
        createSource,
        updateSource,
        deleteSource
      }}
    >
      {props.children}
    </TeamContext.Provider>
  );
};

export default TeamState;
