export const FACEBOOK_ADS = 'Facebook Ads';
export const FACEBOOK_LEAD_ADS = 'Facebook Lead Ads';
export const WHATSAPP_BUSINESS = 'Whatsapp Business';
export const FACEBOOK_INSIGHTS = 'Facebook Insights';
export const INSTAGRAM = 'Instagram';
export const MESSENGER = 'Messenger';
export const WHATSAPP_BAILEYS = 'Whatsapp Baileys';
export const YOUTUBE = 'Youtube';
export const GOOGLE_ADS = 'Google Ads';
export const GOOGLE_ANALYTICS = 'Google Analytics';
export const GOOGLE_BUSINESS = 'Google Business';
export const TIKTOK = 'TikTok';

export const SOURCE_KRAQUEN = 'Kraquen';
export const SOURCE_SALES_U = 'Sales U';
export const SOURCE_WARBOX = 'Warbox';

export const SOURCE_WEBHOOK = 'webhook';

export const POCKETS_SOURCES_TRIGGERS = [FACEBOOK_LEAD_ADS];

export const POCKETS_SOURCES_ACTIONS = [SOURCE_KRAQUEN, SOURCE_SALES_U, SOURCE_WARBOX, SOURCE_WEBHOOK];

export const POCKETS_SOURCES = [...POCKETS_SOURCES_TRIGGERS, ...POCKETS_SOURCES_ACTIONS];

export const API_GRAPH_SOURCES = [FACEBOOK_ADS, FACEBOOK_LEAD_ADS, WHATSAPP_BUSINESS];

export const REFRESH_TOKEN_SOURCES = [YOUTUBE, GOOGLE_ADS, GOOGLE_ANALYTICS, GOOGLE_BUSINESS, TIKTOK];

export const AD_ACCOUNT_SOURCES = [FACEBOOK_INSIGHTS, INSTAGRAM, MESSENGER, ...REFRESH_TOKEN_SOURCES, ...API_GRAPH_SOURCES];

export type TAD_ACCOUNTS = (typeof AD_ACCOUNT_SOURCES)[number];
